import { render, staticRenderFns } from "./SpecialPrice.vue?vue&type=template&id=9bf3e4ba&"
import script from "./SpecialPrice.vue?vue&type=script&lang=ts&"
export * from "./SpecialPrice.vue?vue&type=script&lang=ts&"
import style0 from "./SpecialPrice.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports



































































































































import { Coupon } from "@/api/mine.api";
import { countdown, monthday } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import moment from "moment";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class SpecialPrice extends Vue {
  @Prop({ default: 0 }) private readonly price!: number | string;
  @Prop({ default: 0 }) private readonly sale_price!: number | string;
  @Prop({ default: 0 }) private readonly repertory!: number | string;
  @Prop({ default: 0 }) private readonly bazaar_price!: number | string;
  @Prop({ default: "Steam官方价" }) private readonly bazaar_text!: string;
  @Prop({ default: "" }) private readonly discount_tag!: string;
  @Prop({ default: "" }) private readonly title!: number | string;
  @Prop({ default: 0 }) private readonly finish_time!: number;
  @Prop({ default: undefined }) private readonly coupon!: Coupon;
  @Prop({ default: 0 }) private readonly limit_number!: number | string;
  @Prop({ default: 0 }) private readonly limit_day!: number | string;
  @Prop() gold_price!:number
  @Prop() pay_type!:number
  @Prop() timeFlag!:boolean 
  @Prop() isVip!:boolean
  @Prop() gold_unique!:boolean
  @Prop() isVipMember!:boolean;
  @Prop() home_sort!:boolean;
  @Prop() presell!:boolean;


  @Watch('timeFlag')
  ontimeFlagChanged(newval:any,oldval:any){

  }
  timer: number = 0;
  get showUp(){
    return this.coupon&&this.coupon.price < (Number(this.price)/100)
  }
  countdown = ''
  @Emit()
  toMemberPage(){
    
  }
  get finishDate(){
    return moment(this.finish_time * 1000).format('预售商品（最晚将于YYYY年MM月DD日HH点前发货）');
  }
  get rules() {
    if (this.limit_day == undefined || this.limit_number == undefined)
      return "";
    if (this.limit_day === -1) return "";
    if (this.limit_day === 0) return `每人限购${this.limit_number}件`;
    return `每人每${this.limit_day}天限购${this.limit_number}件`;
  }
  get discountText() {
    const { coupon, sale_price } = this;
    if (!coupon) return `限时特卖`;

    return `专属价`;
  }

  destroy() {
    clearInterval(this.timer);
  }
  lineThrough = {};
  created() {
        console.log(this.price);


    if (this.sale_price != 0) {
      this.lineThrough = {
        "text-decoration": "line-through",
      };
    }
  }
  flag = false;
  mounted() {
    const { day, hour, minute, second } = countdown(this.finish_time);
    if (Number(day) <= 7) {
      this.flag = true;
    }
    this.timer = setInterval(() => {
      if (!this.finish_time) return;
      const { day, hour, minute, second } = countdown(this.finish_time);
      if (
          Number(day) == 0 &&
          Number(hour) == 0 &&
          Number(minute) == 0 &&
          Number(second) == 0
        ) this.flag = false
      if(Number(day)==0&&Number(hour) == 0&&Number(minute) == 0&&Number(second) == 0){
        this.flag = false
      }
      if(!this.gold_unique){
        if(this.isVipMember&&(this.gold_price&&this.pay_type != 0)){
          this.countdown = `
            <div class="countdown-tip-member">距结束${day}天</div>
            <div class="countdown-text-member">
              <span>${hour}</span>:<span>${minute}</span>:<span>${second}</span>
            </div>
          `;
        }else{
          this.countdown = `
            <div class="countdown-tip">距结束${day}天</div>
            <div class="countdown-text">
              <span>${hour}</span>:<span>${minute}</span>:<span>${second}</span>
            </div>
          `;
        }
      }else{
       if((this.gold_price&&this.pay_type != 0)&&this.isVipMember) {
            this.countdown = `
        <div class="countdown-tip-member">距结束${day}天</div>
        <div class="countdown-text-member">
          <span>${hour}</span>:<span>${minute}</span>:<span>${second}</span>
        </div>
      `;
      }
          else{
        this.countdown = `
        <div class="countdown-tip-member">距结束${day}天</div>
        <div class="countdown-text-member">
          <span>${hour}</span>:<span>${minute}</span>:<span>${second}</span>
        </div>
      `;
          }
    }
    }, 1000);
  }
}
